<template>
  <div class="personal-course-layout">
    <div class="wrapper">
      <Table :columns="columns" :data="courses" @on-row-click="handleRowClick">
        <template slot-scope="{ row, index }" slot="startTime">
          <span>{{ row.startTime | formatTime('yyyy-MM-dd hh:mm:ss') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="endTime">
          <span v-if="row.endTime">{{ row.endTime | formatTime('yyyy-MM-dd hh:mm:ss') }}</span>
          <span v-else>未设置</span>
        </template>
        <template slot-scope="{ row, index }" slot="status">
          <span>{{ getStatusByValue(row.status).label }}</span>
        </template>
      </Table>
      <Page :total="total" size="small" style="margin-top: 20px" @on-change="handlePageChange" />
    </div>
  </div>
</template>

<script>
import CourseAPI from '@/api/course'
import { getStatusByValue } from '@/util/courseStatus'
export default {
  name: 'PersonalCourse',
  data() {
    return {
      courses: [],
      columns: [
        { key: 'name', title: '课程名' },
        { key: 'category', title: '类别', width: 180 },
        {
          key: 'startTime',
          title: '开始时间',
          slot: 'startTime',
          width: 180
        },
        {
          key: 'endTime',
          title: '结束时间',
          slot: 'endTime',
          width: 180
        },
        { key: 'status', title: '状态', slot: 'status', width: 80 }
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0
    }
  },
  mounted() {
    this.requestMyCourse()
  },
  methods: {
    async requestMyCourse() {
      const payload = {
        pageNumber: this.currentPage - 1,
        pageSize: this.pageSize
      }
      try {
        const { res } = await CourseAPI.getMyCourse(payload)
        this.courses = res.data
        this.total = res.total
      } finally {
      }
    },
    getStatusByValue(value) {
      return getStatusByValue(value)
    },
    handleRowClick(row) {
      this.$router.push(`/manage/course/detail/${row.courseId}`)
    },
    handlePageChange(curPage) {
      this.currentPage = curPage
      this.requestMyCourse()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../theme/mixin';
.personal-course-layout {
  padding: 20px;
}
.wrapper {
  .--mixin-shadow;
  background: white;
  padding: 10px;
  text-align: initial;
}
</style>
