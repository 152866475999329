var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"personal-course-layout"},[_c('div',{staticClass:"wrapper"},[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.courses},on:{"on-row-click":_vm.handleRowClick},scopedSlots:_vm._u([{key:"startTime",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatTime")(row.startTime,'yyyy-MM-dd hh:mm:ss')))])]}},{key:"endTime",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(row.endTime)?_c('span',[_vm._v(_vm._s(_vm._f("formatTime")(row.endTime,'yyyy-MM-dd hh:mm:ss')))]):_c('span',[_vm._v("未设置")])]}},{key:"status",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.getStatusByValue(row.status).label))])]}}])}),_c('Page',{staticStyle:{"margin-top":"20px"},attrs:{"total":_vm.total,"size":"small"},on:{"on-change":_vm.handlePageChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }